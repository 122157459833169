import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Statistic, Button, Space, Input, message, Tag, Select } from 'antd';
import { UserOutlined, EditOutlined, LinkOutlined } from '@ant-design/icons';
import { getQuoteStageLabel } from '../../../utils/quoteStageMapper';
import { getQuoteTypeLabel } from '../../../utils/quoteTypeMapper';
import { QuoteSummaryProps } from '../../../types';
import { formatCurrency } from './utils';

const { Option } = Select;

const RATE_CARD_OPTIONS = ['SUDP', 'ADMO', 'BWPv2'];

const QuoteSummary: React.FC<QuoteSummaryProps> = ({ 
  owner, 
  totalMRR, 
  totalTCV, 
  quoteStage, 
  quoteType,
  opticQuote,
  onOpticQuoteEdit,
  lineCommit,
  onLineCommitEdit
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(opticQuote);
  const [rateCard, setRateCard] = useState(RATE_CARD_OPTIONS[0]);
  const [isEditingRateCard, setIsEditingRateCard] = useState(false);
  const [isEditingLineCommit, setIsEditingLineCommit] = useState(false);
  const [editLineCommit, setEditLineCommit] = useState(lineCommit);
  const isWirelessQuote = quoteType === 612100001;

  // Update local state when prop changes
  useEffect(() => {
    setEditLineCommit(lineCommit);
  }, [lineCommit]);

  useEffect(() => {
    setEditValue(opticQuote);
  }, [opticQuote]);

  const handleSave = async () => {
    try {
      const valueToSave = editValue === 'Q-' ? '' : editValue;
      
      if (!valueToSave || valueToSave.startsWith('Q-')) {
        await onOpticQuoteEdit(valueToSave);
        setIsEditing(false);
      } else {
        message.error('OptiC Quote must be empty or start with Q-');
      }
    } catch (error) {
      // Error handling is done in the parent component
    }
  };

  const handleEditStart = () => {
    setEditValue(opticQuote || 'Q-');
    setIsEditing(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditValue(e.target.value);
  };

  const handleLineCommitChange = (value: string) => {
    // Allow zero or positive whole numbers
    const numValue = parseInt(value);
    if (!isNaN(numValue) && numValue >= 0) {
      setEditLineCommit(numValue.toString());
    }
  };

  const handleLineCommitSave = async () => {
    try {
      await onLineCommitEdit(editLineCommit);
      setIsEditingLineCommit(false);
    } catch (error) {
      console.error('Error saving line commit:', error);
    }
  };

  const getQuoteStageColor = (stage: number): string => {
    switch (stage) {
      case 612100000: return 'default';     // Draft
      case 612100001: return 'processing';   // In Queue
      case 612100002: return 'warning';      // Pending Sales
      case 612100003: return 'blue';         // Submitted to SA
      case 612100004: return 'purple';       // Pending COR
      case 612100005: return 'cyan';         // Pending 3rd Party/MAT
      case 612100006: return 'geekblue';     // NIKA Requested
      case 612100007: return 'volcano';      // Finance Review
      case 612100008: return 'orange';       // Waiting on CSE
      case 755280001: return 'magenta';      // MAT with DBM
      case 612100009: return 'success';      // Completed
      case 612100010: return 'gold';         // Technical Review
      case 612100011: return 'volcano';         // Pending TAG
      default: return 'default';
    }
  };

  const getQuoteTypeColor = (type: number): string => {
    switch (type) {
      case 612100000: return 'blue';      // Wireline
      case 612100001: return 'green';     // Wireless
      case 612100002: return 'purple';    // Data Centre
      case 612100003: return 'cyan';      // IoT
      case 612100004: return 'magenta';   // Small Business
      default: return 'default';
    }
  };

  return (
    <Card size="small">
      <Row gutter={[8, 8]} align="middle" justify="space-between">
        <Col flex="1">
          <Statistic
            title="Owner"
            value={owner}
            prefix={<UserOutlined />}
            valueStyle={{ fontSize: '14px', fontWeight: 'bold' }}
          />
        </Col>
        <Col flex="1">
          <div>
            <div style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '14px' }}>OptiC Quote</div>
            <div style={{ fontSize: '14px', fontWeight: 'bold', whiteSpace: 'nowrap' }}>
              {isEditing ? (
                <Space>
                  <Input
                    value={editValue}
                    onChange={handleChange}
                    onPressEnter={handleSave}
                    onBlur={handleSave}
                    autoFocus
                  />
                </Space>
              ) : (
                <Space>
                  {opticQuote || '-'}
                  <Button
                    type="text"
                    icon={<EditOutlined />}
                    onClick={handleEditStart}
                    disabled={quoteStage === 612100000}
                  />
                  {opticQuote && (
                    <Button
                      type="text"
                      icon={<LinkOutlined />}
                      onClick={() => window.open(`https://rogersb2b.lightning.force.com/_ui/search/ui/UnifiedSearchResults?str=${opticQuote}`, '_blank')}
                    />
                  )}
                </Space>
              )}
            </div>
          </div>
        </Col>
        <Col flex="1">
          <div>
            <div style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '14px', marginBottom: '8px' }}>Quote Type</div>
            <Tag color={getQuoteTypeColor(quoteType)} style={{ margin: 0, fontWeight: 'bold' }}>
              {getQuoteTypeLabel(quoteType)}
            </Tag>
          </div>
        </Col>
        <Col flex="1">
          <div>
            <div style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '14px', marginBottom: '8px' }}>Quote Stage</div>
            <Tag color={getQuoteStageColor(quoteStage)} style={{ margin: 0, fontWeight: 'bold', color: quoteStage === 612100009 ? '#52c41a' : undefined }}>
              {getQuoteStageLabel(quoteStage)}
            </Tag>
          </div>
        </Col>
        {isWirelessQuote && (
          <Col flex="1">
            <div>
              <div style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '14px', marginBottom: '4px' }}>Line Commit</div>
              <div style={{ fontSize: '14px', fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                {isEditingLineCommit ? (
                  <Input
                    type="number"
                    value={editLineCommit}
                    onChange={(e) => handleLineCommitChange(e.target.value)}
                    onPressEnter={handleLineCommitSave}
                    onBlur={handleLineCommitSave}
                    min={0}
                    autoFocus
                    style={{ width: '100%' }}
                  />
                ) : (
                  <Space>
                    {lineCommit || '0'}
                    <Button
                      type="text"
                      icon={<EditOutlined />}
                      onClick={() => {
                        setEditLineCommit(lineCommit);
                        setIsEditingLineCommit(true);
                      }}
                      style={{ padding: '0 4px' }}
                    />
                  </Space>
                )}
              </div>
            </div>
          </Col>
        )}
        <Col flex="1">
          <Statistic
            title={isWirelessQuote ? "Total MSF" : "Total MRR"}
            value={formatCurrency(totalMRR)}
            valueStyle={{ color: '#000000', fontSize: '14px', fontWeight: 'bold' }}
          />
        </Col>
        <Col flex="1">
          {isWirelessQuote ? (
            <div>
              <div style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '14px', marginBottom: '4px' }}>Rate Card</div>
              <div style={{ fontSize: '14px', fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                {isEditingRateCard ? (
                  <Select
                    value={rateCard}
                    onChange={(value) => {
                      setRateCard(value);
                      setIsEditingRateCard(false);
                    }}
                    style={{ width: '100%' }}
                    autoFocus
                    onBlur={() => setIsEditingRateCard(false)}
                  >
                    {RATE_CARD_OPTIONS.map(option => (
                      <Option key={option} value={option}>{option}</Option>
                    ))}
                  </Select>
                ) : (
                  <Space>
                    {rateCard}
                    <Button
                      type="text"
                      icon={<EditOutlined />}
                      onClick={() => setIsEditingRateCard(true)}
                      style={{ padding: '0 4px' }}
                    />
                  </Space>
                )}
              </div>
            </div>
          ) : (
            <Statistic
              title="Total TCV"
              value={formatCurrency(totalTCV)}
              valueStyle={{ color: '#000000', fontSize: '14px', fontWeight: 'bold' }}
            />
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default QuoteSummary;
